import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  Container
} from "@material-ui/core";
import { Link } from "react-router-dom";
import oficina from "../images/oficina1.jpeg"

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#000", // Fondo negro
    color: "#fff",           // Texto blanco
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(2),
  },
  root: {
    display: "flex",
    alignItems: "center",
    // En pantallas pequeñas, apilamos verticalmente
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  imageContainer: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
  },
  image: {
    width: "100%",
    height: "auto",
    display: "block",
  },
  textContainer: {
    width: "40%",
    marginLeft: 20, // 20px de separación entre columnas
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: 0,
    },
  },
  heading: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  paragraph: {
    textAlign: "justify", // Justifica el texto completamente
    textJustify: "inter-word", // Distribuye los espacios entre palabras
    lineHeight: "1.6", // Ajuste de altura de línea para mejor legibilidad
    width: "100%", // Asegura que ocupe todo el ancho disponible
    display: "block", // Evita que Material-UI lo trate como inline
    wordSpacing: "0.1em", // Opcional: Ajusta el espacio entre palabras para más uniformidad marginBottom: theme.spacing(2),
    fontSize: 15,
  },
  button: {
    backgroundColor: "#fff",
    color: "#000",
    marginTop: theme.spacing(4),
    "&:hover": {
      backgroundColor: "#ccc", // Efecto hover
    },
  },
  buttonLarge: {
    fontSize: "1.5rem",        // Aumenta el tamaño de letra
    padding: theme.spacing(1, 3), // Aumenta relleno vertical y horizontal
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",      // Opcional: aún más grande en pantallas medianas+
    },
  },
}));

const Quienes = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box className={classes.root}>
        {/* Columna Izquierda: Imagen (60%) */}
        <Box className={classes.imageContainer}>
          <img
            src={oficina} // Ajusta la ruta a tu imagen
            alt="Oficina"
            className={classes.image}
          />
        </Box>

        {/* Columna Derecha: Texto (40%) */}
        <Box className={classes.textContainer}>
          <Typography variant="h4" className={classes.heading}>
            Ámbito Inmobiliaria:
          </Typography>

          <Typography variant="h4" className={classes.heading}>
            Experiencia y Confianza en el Mercado
          </Typography>

          <Typography variant="2" className={classes.paragraph}>
            En Ámbito Inmobiliaria, combinamos más de 20 años de experiencia con
            un enfoque estratégico en el desarrollo de proyectos de inversión,
            administración y alquiler de propiedades. Brindamos asesoramiento
            integral, tanto administrativo como legal, asegurando operaciones
            seguras y eficientes.
          </Typography>

          <Typography variant="2" className={classes.paragraph}>
            Ofrecemos una amplia cartera de departamentos, casas, oficinas,
            cocheras, terrenos y locales comerciales, adaptándonos a las
            necesidades del mercado. Incorporamos una plataforma digital
            innovadora que agiliza la búsqueda de propiedades y facilita la
            interacción con nuestros clientes a través de redes sociales.
          </Typography>

          <Typography variant="2" className={classes.paragraph}>
            Transparencia, tecnología y gestión profesional al servicio de tu
            inversión.
          </Typography>

          {/* Botón con fondo blanco y texto negro */}
          {/* <Link to="/propiedades" style={{ textDecoration: "none" }}>
            <Button variant="contained" className={classes.buttonLarge}>
              Encontrá tu Propiedad
            </Button>
          </Link> */}
        </Box>
      </Box>
    </Container>
  );
};

export default Quienes;

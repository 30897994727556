import React, { useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { getPropiedades } from "../helpers/rutaPropiedades";
import imagennavbar from "../images/imagennavbar2.jpeg"
import logojpg1 from "../images/logojpg3.jpeg"
// Material UI v4 Components
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  CssBaseline,
  makeStyles
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../images/recicla.png";

const useStyles = makeStyles((theme) => ({
  /* Contenedor "hero" que incluye la imagen de fondo */
  hero: {
    position: "relative",
    width: "100%",
    height: 200, // altura aproximada de la sección
    backgroundImage: `url(${imagennavbar})`, // Ajusta la ruta
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  /* AppBar semitransparente sobre la imagen */
  appBar: {
    backgroundColor: "rgba(255, 255, 255, 0)", // Fondo semitransparente
    color: "#000",  // Texto negro para contraste
    boxShadow: "none",
    // borderBottom: `1px solid ${theme.palette.divider}`,
    position: "static", // Se mantiene estático

  },
  logo: {
    height: "100px",  // Aumenta el tamaño, prueba con 120px o más
    width: "auto",     // Mantiene la proporción
    margin: theme.spacing(1),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    width: "80px",

    
  },
  drawerPaper: {
    width: 200,
    backgroundColor: "rgba(255, 255, 255, 0.7)", // Fondo semitransparente
    transition: "transform 0.3s ease-in-out",
    fontSize: "3rem", // Letras más grandes
    paddingTop: theme.spacing(11),
    color: "black", // Letra blanca
  },
  listItem: {
    fontSize: "1.5rem", // Texto en el menú más grande
    fontWeight: "bold", // Opcional: hace el texto más grueso
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Sombra negra suave
    
    // "&:hover": {
    //   backgroundColor: theme.palette.action.hover,
    //   backgroundColor: "rgba(255, 255, 255, 0)", // Fondo semitransparente
    // },
  },
  menuIcon: {
    fontSize: "4rem", // Ajusta el tamaño a tu preferencia (3rem es solo un ejemplo)
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)", // Sombra negra suave
    color: "black", // Letra blanca
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#FBE2BC", // Color en responsive (blanco)
      color: "black", // Texto negro para contraste
    },
  },
  logo: {
    height: "100px",
    margin: theme.spacing(1),
  },
  title: {
    fontFamily: "Quicksand",
    // fontFamily: "Helvetica, Arial, sans-serif",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)", // Sombra negra suave
    fontSize: "4rem", // Tamaño normal en pantallas grandes
    color: "#fff", // Letra blanca
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.5rem", // Tamaño más pequeño en móviles
    },
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [user, setUser] = useState("Iniciar sesión");
  const [payload, setPayload] = useState({ role: "" });
  const [propiedades, setPropiedades] = useState({ data: {}, loading: true });
  const [drawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("usuario")) || "Iniciar Sesión");
    actualizarData();
    checkToken();
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    actualizarData();
    // eslint-disable-next-line
  }, []);

  const actualizarData = () => {
    getPropiedades(0, 100).then((datos) => {
      setPropiedades({
        data: datos,
        loading: false,
      });
    });
  };

  const handleLogin = () => {
    localStorage.setItem("token", JSON.stringify(""));
    localStorage.setItem("id", JSON.stringify(""));
    localStorage.setItem("usuario", JSON.stringify("Iniciar Sesión"));
    setUser("Iniciar Sesión");
    setPayload({ role: "" });
    history.push("/login");
  };

  const checkToken = () => {
    const token = JSON.parse(localStorage.getItem("token")) || "";
    if (token.length > 0) {
      const token_decode = jwt_decode(localStorage.getItem("token"));
      setPayload(token_decode.usuario);
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/" className={classes.listItem}>
        <ListItemText 
          primary="Home" 
          primaryTypographyProps={{ style: { fontSize: "1.5rem", fontWeight: "bold" } }} 
        />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/propiedades"
          className={classes.listItem}
        >
          <ListItemText 
            primary="Buscar Propiedades" 
            primaryTypographyProps={{ style: { fontSize: "1.5rem", fontWeight: "bold" } }} 
          />

        </ListItem>
        <ListItem
          button
          component="a"
          href="https://docs.google.com/forms/d/e/1FAIpQLScTpePk0bQ3HVpbFP-UCRkmYs6Smkl3_Ck6QT6v9SSG5M8LOg/viewform"
          target="_blank"
          className={classes.listItem}
        >
          {/* <ListItemText primary="Contáctanos" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/quienes"
          className={classes.listItem}
        >
          <ListItemText primary="Quienes Somos" /> */}
        </ListItem>
        <Divider />
        {/* <ListItem button component={Link} to="/eco" className={classes.listItem}>
          <img src={logo} alt="Logo" className={classes.logo} />
          <ListItemText primary="Conciencia ECO" />
        </ListItem> */}
        {payload.role === "ADMIN_ROLE" && (
          <ListItem
            button
            component={Link}
            to="/admin"
            className={classes.listItem}
          >
            <ListItemText primary="Administrador" />
          </ListItem>
        )}
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleLogin} className={classes.listItem}>
          <ListItemText primary={user} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.hero}>
      <CssBaseline />
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
        <IconButton
  edge="start"
  className={classes.menuButton}
  color="inherit"
  aria-label="menu"
  onClick={toggleDrawer(true)}
>
  <MenuIcon className={classes.menuIcon} />
</IconButton>
          <Typography variant="h6" className={classes.title}>
            Ambito Inmobiliaria
          </Typography>
          <img src={logojpg1} alt="Logo" className={classes.logo} />
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          BackdropProps: {
            style: { backgroundColor: "rgba(0,0,0,0.3)" },
          },
        }}
      >
        {list()}
      </Drawer>
    </div>
  );
};

export default NavBar;


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPropiedades } from "../helpers/rutaPropiedades";
import Cargando from "../components/Cargando";

// Material UI v4 Components
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";

// Material UI Icons
import HomeIcon from "@material-ui/icons/Home";
import HotelIcon from "@material-ui/icons/Hotel";
import BathtubIcon from "@material-ui/icons/Bathtub";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
    fontFamily: "Quicksand",
    // fontFamily: "Helvetica, Arial, sans-serif",
    fontWeight: "bold",
    fontSize: "1.8rem",
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  card: {
    position: "relative",
    width: "100%",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: theme.shadows[6],
    },
  },
  mediaContainer: {
    position: "relative",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // Proporción 16:9
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.4)",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  chip: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  title: {
    fontSize: "1.7rem",
    fontFamily: "Helvetica, Arial, sans-serif",
    fontWeight: "bold",
  },
  bodyText: {
    fontSize: "1rem",
  },
  value: {
    fontSize: "1.3rem",
    fontWeight: 600,
  },
  footer: {
    fontSize: "1rem",
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

const Propiedades = () => {
  const classes = useStyles();
  const [propiedades, setPropiedades] = useState({
    data: {},
    loading: true,
  });

  useEffect(() => {
    getPropiedades().then((propiedades) => {
      setPropiedades({
        data: propiedades,
        loading: false,
      });
    });
  }, []);

  const renderCard = (propiedad) => {
    let chipColor;
    switch (propiedad.EstadoPropiedad) {
      case "Venta":
        chipColor = "secondary";
        break;
      case "Vendida":
        chipColor = "default";
        break;
      case "Alquiler":
        chipColor = "primary";
        break;
      case "Alquilada":
        chipColor = "default";
        break;
      case "Venta-Alquiler":
        chipColor = "default";
        break;
      default:
        chipColor = "default";
    }

    return (
      <Grid item xs={12} sm={4} md={4} lg={4} key={propiedad._id}>
        <Card className={classes.card}>
          <CardActionArea component={Link} to={`/propiedad/${propiedad._id}`}>
            <div className={classes.mediaContainer}>
              <CardMedia
                className={classes.media}
                image={propiedad.imag1}
                title={propiedad.Titulo}
              />
              <div className={classes.overlay}>
                <div>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    className={classes.title}
                  >
                    {propiedad.Titulo}
                  </Typography>
                  <Chip
                    label={propiedad.EstadoPropiedad}
                    color={chipColor}
                    className={classes.chip}
                  />
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.bodyText}
                  >
                    {propiedad.DireccionFisica}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    className={classes.value}
                  >
                    {propiedad.Valor}
                  </Typography>
                </div>
                <div style={{ display: "flex", marginTop: 8 }}>
                  <div className={classes.iconText}>
                    <HomeIcon className={classes.icon} />
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.bodyText}
                    >
                      {propiedad.SuperficieTotal}
                    </Typography>
                  </div>
                  <div className={classes.iconText}>
                    <HotelIcon className={classes.icon} />
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.bodyText}
                    >
                      {propiedad.Dormitorio}
                    </Typography>
                  </div>
                  <div className={classes.iconText}>
                    <BathtubIcon className={classes.icon} />
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.bodyText}
                    >
                      {propiedad.Baño}
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="subtitle2"
                  className={classes.footer}
                >
                  {propiedad.CodigoPropiedad} {propiedad.id}
                </Typography>
              </div>
            </div>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };

  // Filtrar globalmente para mostrar solo las propiedades con mostrar_portada === false
  const filteredProperties =
    propiedades.data.propiedades &&
    propiedades.data.propiedades.filter(
      (propiedad) => propiedad.mostrar_portada === false
    );

  return (
    <main>
      {propiedades.loading ? (
        <Cargando />
      ) : (
        <Container>
          <Typography variant="h4" className={classes.sectionHeader}>
            Propiedades Destacadas
          </Typography>
          <Divider className={classes.divider} />
          <Grid container spacing={2}>
            {filteredProperties && filteredProperties.map(renderCard)}
          </Grid>
        </Container>
      )}
    </main>
  );
};

export default Propiedades;



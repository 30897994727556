import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Importar Material-UI (v4)
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  Chip,
  Divider,
} from "@material-ui/core";

// Íconos de Material-UI
import HomeIcon from "@material-ui/icons/Home";
import HotelIcon from "@material-ui/icons/Hotel";
import BathtubIcon from "@material-ui/icons/Bathtub";

import { getPropiedades } from "../helpers/rutaPropiedades";
import Cargando from "../components/Cargando";
import Busqueda from "../components/Busqueda";
import Footer from "../components/Footer";

// Importaciones existentes
import "../css/propiedades.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Se mantiene, pero no usamos clases

// Estilos tomados del primer código
const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  sectionHeader: {
    margin: theme.spacing(4, 0, 2),
    textAlign: "center",
    fontFamily: "Quicksand",
    fontWeight: "bold",
    fontSize: "1.8rem",
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  card: {
    position: "relative",
    width: "100%",
    transition: "transform 0.2s, box-shadow 0.2s",
    "&:hover": {
      transform: "scale(1.02)",
      boxShadow: theme.shadows[6],
    },
  },
  mediaContainer: {
    position: "relative",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // Proporción 16:9
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.4)",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  chip: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  title: {
    fontSize: "1.7rem",
    fontFamily: "Helvetica, Arial, sans-serif",
    fontWeight: "bold",
  },
  bodyText: {
    fontSize: "1rem",
  },
  value: {
    fontSize: "1.3rem",
    fontWeight: 600,
  },
  footerText: {
    fontSize: "1rem",
  },
  iconText: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
}));

const Propiedades = () => {
  const classes = useStyles();

  const [muestrapagina, setMuestrapagina] = useState(true);
  const [propiedades, setPropiedades] = useState({
    data: [],
    loading: true,
  });
  const [propiedadesFiltradas, setPropiedadesFiltradas] = useState([]);
  const [estadoSelect, setEstadoSelect] = useState("");
  const [tipoSelect, setTipoSelect] = useState("");
  const [lugarSelect, setLugarSelect] = useState("");
  const [ambientesSelect, setAmbientesSelect] = useState("");

  // Cargar propiedades
  useEffect(() => {
    getPropiedades().then((resp) => {
      setPropiedades({
        data: resp,
        loading: false,
      });
    });
  }, []);

  // Filtrado
  const filtrarPropiedades = () => {
    const estadoFilter = propiedades.data.propiedades.filter((inmueble) => {
      return estadoSelect === "" || inmueble.EstadoPropiedad === estadoSelect;
    });
    const tipoFilter = estadoFilter.filter((inmueble) => {
      return tipoSelect === "" || inmueble.TipoPropiedad === tipoSelect;
    });
    const lugarFilter = tipoFilter.filter((inmueble) => {
      return lugarSelect === "" || inmueble.Lugar === lugarSelect;
    });
    const ambientesFilter = lugarFilter.filter((inmueble) => {
      return (
        ambientesSelect === "" || inmueble.Ambientes === ambientesSelect
      );
    });
    setPropiedadesFiltradas(ambientesFilter);
  };

  // Función para asignar color al Chip según el EstadoPropiedad
  const getChipColor = (estado) => {
    switch (estado) {
      case "Venta":
        return "secondary";
      case "Vendida":
        return "default";
      case "Alquiler":
        return "primary";
      case "Alquilada":
        return "default";
      case "Venta-Alquiler":
        return "default";
      default:
        return "default";
    }
  };

  // Renderizar una card con el estilo del primer código
  const renderCard = (propiedad) => {
    return (
      <Grid item xs={12} sm={4} md={4} key={propiedad._id}>
        <Card className={classes.card}>
          {/* Link a detalle, si deseas */}
          <CardActionArea component={Link} to={`/propiedad/${propiedad._id}`}>
            <div className={classes.mediaContainer}>
              <CardMedia
                className={classes.media}
                image={propiedad.imag1} // Ajusta según tu API
                title={propiedad.Titulo}
              />
              {/* Overlay con la info */}
              <div className={classes.overlay}>
                <div>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    className={classes.title}
                  >
                    {propiedad.Titulo}
                  </Typography>
                  <Chip
                    label={propiedad.EstadoPropiedad}
                    color={getChipColor(propiedad.EstadoPropiedad)}
                    className={classes.chip}
                  />
                  <Typography
                    variant="body2"
                    component="p"
                    className={classes.bodyText}
                  >
                    {propiedad.DireccionFisica}
                  </Typography>
                  <Typography
                    variant="h6"
                    component="p"
                    className={classes.value}
                  >
                    {propiedad.Valor}
                  </Typography>
                </div>
                {/* Íconos (superficie, dormitorios, baños, etc.) */}
                <div style={{ display: "flex", marginTop: 8 }}>
                  <div className={classes.iconText}>
                    <HomeIcon className={classes.icon} />
                    <Typography variant="body2" component="span">
                      {propiedad.SuperficieTotal}
                    </Typography>
                  </div>
                  <div className={classes.iconText}>
                    <HotelIcon className={classes.icon} />
                    <Typography variant="body2" component="span">
                      {propiedad.Dormitorio}
                    </Typography>
                  </div>
                  <div className={classes.iconText}>
                    <BathtubIcon className={classes.icon} />
                    <Typography variant="body2" component="span">
                      {propiedad.Baño}
                    </Typography>
                  </div>
                </div>
                <Typography variant="subtitle2" className={classes.footerText}>
                  {propiedad.CodigoPropiedad} {propiedad.id}
                </Typography>
              </div>
            </div>
          </CardActionArea>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      {/* Barra de búsqueda */}
      <Busqueda
        setEstadoSelect={setEstadoSelect}
        setTipoSelect={setTipoSelect}
        setLugarSelect={setLugarSelect}
        setAmbientesSelect={setAmbientesSelect}
        filtrarPropiedades={filtrarPropiedades}
        setMuestrapagina={setMuestrapagina}
      />

      {/* Muestra 'Cargando' si está en proceso */}
      {propiedades.loading && <Cargando />}

      {/* Si ya cargó */}
      {!propiedades.loading && (
        <Container className={classes.rootContainer}>
          {/* Título */}
          {!propiedades.loading && (
            <>
              <Typography variant="h4" className={classes.sectionHeader}>
                Propiedades en Venta y Alquiler
              </Typography>
              <Divider className={classes.divider} />
            </>
          )}

          {/* Grid de tarjetas */}
          <Grid container spacing={2}>
            {/* Mostrar todas las propiedades si muestrapagina === true */}
            {muestrapagina
              ? propiedades.data.propiedades.map((propiedad) =>
                  renderCard(propiedad)
                )
              : // Si no hay propiedades filtradas, mostramos mensaje
              propiedadesFiltradas.length === 0 ? (
                <Grid item xs={12}>
                  <Typography variant="h6" color="textSecondary">
                    No se encontraron propiedades para la búsqueda...
                  </Typography>
                </Grid>
              ) : (
                // Sino, mostramos las filtradas
                propiedadesFiltradas.map((propiedad) =>
                  renderCard(propiedad)
                )
              )}
          </Grid>
        </Container>
      )}

      {/* Footer */}
      <Footer />
    </>
  );
};

export default Propiedades;

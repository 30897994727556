import React from "react";
import { createMuiTheme, ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, MenuItem, Button } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#333333", // tono neutro oscuro
    },
    secondary: {
      main: "#f5f5f5", // tono neutro claro
    },
  },
  typography: {
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: "100%",
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: theme.spacing(2),
    width: "50%", // En pantallas grandes ocupa el 50%
    margin: "auto", // Centrado
    [theme.breakpoints.down("sm")]: {
      width: "100%", // En móviles ocupa todo el ancho
    },
  },
  
  formControl: {
    minWidth: 200,
    flex: 1,
    width: "100%", // Ocupa todo el ancho disponible en su fila
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%", // En móviles, cada uno ocupa el 100% del ancho
    },
  },
  
  button: {
    marginTop: theme.spacing(2),
  },
}));

const Busqueda = ({
  setEstadoSelect,
  setTipoSelect,
  setLugarSelect,
  setMuestrapagina,
  filtrarPropiedades,
}) => {
  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    setMuestrapagina(false);
    filtrarPropiedades();
  };

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <form onSubmit={handleSubmit} className={classes.form}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="estado-label">Estado</InputLabel>
            <Select
              labelId="estado-label"
              id="estado-select"
              label="Estado"
              onChange={(e) => setEstadoSelect(e.target.value)}
              defaultValue=""
            >
              <MenuItem value="">
                <em>Elija la opción adecuada</em>
              </MenuItem>
              <MenuItem value="Venta">Venta</MenuItem>
              <MenuItem value="Alquiler">Alquiler</MenuItem>
              <MenuItem value="Venta-Alquiler">Venta-Alquiler</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="tipo-label">Tipo</InputLabel>
            <Select
              labelId="tipo-label"
              id="tipo-select"
              label="Tipo"
              onChange={(e) => setTipoSelect(e.target.value)}
              defaultValue=""
            >
              <MenuItem value="">
                <em>Elija la opción adecuada</em>
              </MenuItem>
              <MenuItem value="Casa">Casa</MenuItem>
              <MenuItem value="Departamento">Departamento</MenuItem>
              <MenuItem value="Terreno">Terreno</MenuItem>
              <MenuItem value="Local Comercial">Local Comercial</MenuItem>
              <MenuItem value="Galpón">Galpón</MenuItem>
              <MenuItem value="Cochera">Cochera</MenuItem>
              <MenuItem value="Oficina">Oficina</MenuItem>
              <MenuItem value="Campo">Campo</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="lugar-label">Lugar</InputLabel>
            <Select
              labelId="lugar-label"
              id="lugar-select"
              label="Lugar"
              onChange={(e) => setLugarSelect(e.target.value)}
              defaultValue=""
            >
              <MenuItem value="">
                <em>Elija la opción adecuada</em>
              </MenuItem>
              <MenuItem value="San Miguel de Tucumán">San Miguel de Tucumán</MenuItem>
              <MenuItem value="Yerba Buena">Yerba Buena</MenuItem>
              <MenuItem value="Tafí del Valle">Tafí del Valle</MenuItem>
              <MenuItem value="Tafí Viejo">Tafí Viejo</MenuItem>
              <MenuItem value="Banda del Río Salí">Banda del Río Salí</MenuItem>
              <MenuItem value="Concepción">Concepción</MenuItem>
              <MenuItem value="Monteros">Monteros</MenuItem>
              <MenuItem value="Famailla">Famailla</MenuItem>
              <MenuItem value="Lules">Lules</MenuItem>
            </Select>
          </FormControl>

          
          <Button
            variant="contained"
            color="primary"
            type="submit"
            startIcon={<SearchIcon />}
            className={classes.button}
          >
            Buscar
          </Button>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default Busqueda;



import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { 
  Fade, 
  Button, 
  Container, 
  IconButton, 
  Typography, 
  makeStyles 
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import image1 from "../images/image1grises1.jpeg"

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    position: "relative",
    // overflow: "hidden",
    width: "100%",
    height: 400, // Tamaño fijo para el contenedor original 500
    margin: theme.spacing(0,0), //(2,0)
  },
  slide: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  image: {
    width: "100%",
    height: "100%", // Forzamos a que la imagen llene el contenedor
    objectFit: "cover", // La imagen se recorta manteniendo la proporción
    display: "block",
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: theme.spacing(2),
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem",
    },
  },
  arrow: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    color: "#fff",
    backgroundColor: "rgba(0,0,0,0.3)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.5)",
    },
  },
  arrowLeft: {
    left: theme.spacing(2),
  },
  arrowRight: {
    right: theme.spacing(2),
  },
  indicators: {
    position: "absolute",
    bottom: theme.spacing(2),
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    gap: theme.spacing(1),
  },
  indicator: {
    width: 12,
    height: 12,
    borderRadius: "50%",
    backgroundColor: "rgba(255,255,255,0.5)",
    cursor: "pointer",
    "&.active": {
      backgroundColor: "#fff",
    },
  },
  buttonLarge: {
    fontSize: "1.5rem",        // Aumenta el tamaño de letra
    padding: theme.spacing(1, 3), // Aumenta relleno vertical y horizontal
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.7rem",      // Opcional: aún más grande en pantallas medianas+
    },
  },
}));

const Carousel = () => {
  const classes = useStyles();

  // Imágenes estáticas del carrusel
  const slides = [
    {
      src: "https://www.arquibase.com/uploads/8/3/4/5/8345765/fachada-de-casa-minimalista-moderna-con-piedra-natural-y-madera-natural_orig.jpg",
      // src: "https://unsplash.com/es/fotos/foto-en-escala-de-grises-de-la-casa-hLJZDUtphk",
      alt: "site 1",
    },
    {
      src: "https://www.bekiahogar.com/images/articulos/72000/72174/6.jpg",
      alt: "site 2",
    },
    {
      src: "https://images.homify.com/c_fill,f_auto,h_500,q_auto,w_1280/v1440249293/p/photo/image/587728/01_Perspectiva___noite.jpg",
      alt: "site 3",
      style: {filter:'grayscale(100%)'},
    },
    {
      src: "https://cdn.shopify.com/s/files/1/0057/5897/6069/articles/increibles-estilos-de-casas-modernas-893879_1296x.jpg?v=1585346727",
      alt: "site 4",
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  // Cambio automático cada 3 segundos
  useEffect(() => {
    const timer = setInterval(() => {
      setActiveStep((prev) => (prev + 1) % slides.length);
    }, 3000);
    return () => clearInterval(timer);
  }, [slides.length]);

  const handleNext = () => {
    setActiveStep((prev) => (prev + 1) % slides.length);
  };

  const handleBack = () => {
    setActiveStep((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const handleIndicatorClick = (index) => {
    setActiveStep(index);
  };

  return (
  
      <div className={classes.carouselContainer}>
        {/* Fade para la transición de imagen */}
        <Fade in={true} key={activeStep} timeout={1000}>
          <div className={classes.slide}>
            <img
              src={slides[activeStep].src}
              alt={slides[activeStep].alt}
              className={classes.image}
            />
          </div>
        </Fade>
        {/* Overlay con título y botón */}
        <div className={classes.overlay}>
          {/* <Typography variant="h3" className={classes.title}>
            Ambito Inmobiliaria
          </Typography> */}
          <Button
            className={classes.buttonLarge}
            variant="contained"
            color="primary"
            component={Link}
            to="/propiedades"
          >
            Encontrá tu Propiedad
          </Button>
        </div>
        {/* Controles manuales: flechas */}
        <IconButton
          className={`${classes.arrow} ${classes.arrowLeft}`}
          onClick={handleBack}
        >
          <ArrowBackIosIcon />
        </IconButton>
        <IconButton
          className={`${classes.arrow} ${classes.arrowRight}`}
          onClick={handleNext}
        >
          <ArrowForwardIosIcon />
        </IconButton>
        {/* Indicadores */}
        <div className={classes.indicators}>
          {slides.map((_, index) => (
            <div
              key={index}
              className={`${classes.indicator} ${
                index === activeStep ? "active" : ""
              }`}
              onClick={() => handleIndicatorClick(index)}
            />
          ))}
        </div>
      </div>
    
  );
};

export default Carousel;

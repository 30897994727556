import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Divider,
  Button,
  TextField,
  Paper,
  IconButton,
  Fade,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import clsx from "clsx";
import emailjs from "emailjs-com";

// Íconos
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import KingBedIcon from "@material-ui/icons/KingBed";
import BathtubIcon from "@material-ui/icons/Bathtub";
import KitchenIcon from "@material-ui/icons/Kitchen";
import PoolIcon from "@material-ui/icons/Pool";
import DirectionsCarIcon from "@material-ui/icons/DirectionsCar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";

// Componentes propios
import SimpleMap from "../components/GoogleMaps";
import Rss from "../components/Rss";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    color: "#333",
    padding: theme.spacing(2),
    backgroundColor: "#fff",
  },
  carouselContainer: {
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.up("md")]: {
      height: 500,
    },
    marginBottom: theme.spacing(2),
    borderRadius: 8,
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
  },
  imageWrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  fadeImage: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    transition: "opacity 0.5s ease-in-out",
    top: 0,
    left: 0,
    borderRadius: 8,
  },
  arrowButton: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "rgba(0,0,0,0.4)",
    color: "#fff",
    zIndex: 2,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.6)",
    },
  },
  arrowLeft: {
    left: theme.spacing(1),
  },
  arrowRight: {
    right: theme.spacing(1),
  },
  thumbnailsContainer: {
    display: "flex",
    overflowX: "auto",
    marginTop: theme.spacing(1),
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  thumbnail: {
    width: 100,
    height: 70,
    objectFit: "cover",
    marginRight: theme.spacing(1),
    cursor: "pointer",
    border: "2px solid transparent",
    borderRadius: 4,
    transition: "transform 0.3s, border-color 0.3s",
    "&:hover": {
      transform: "scale(1.05)",
      borderColor: theme.palette.primary.main,
    },
  },
  thumbnailActive: {
    borderColor: theme.palette.primary.main,
  },
  propertyDataContainer: {
    backgroundColor: "#f8f8f8",
    padding: theme.spacing(2),
    borderRadius: 8,
  },
  dataRow: {
    fontSize: "1.125rem",
    margin: theme.spacing(1, 0),
  },
  horizontalLine: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.divider,
  },
  descriptionContainer: {
    marginTop: theme.spacing(4),
  },
  descriptionTitle: {
    fontSize: "1.25rem",
    fontWeight: 600,
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  descriptionText: {
    fontSize: "1rem",
    marginTop: theme.spacing(1),
    lineHeight: 1.6,
  },
  servicesTitle: {
    fontSize: "1.25rem",
    fontWeight: 600,
    marginTop: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  serviceItem: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontSize: "1.125rem",
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  serviceIcon: {
    marginRight: theme.spacing(1),
  },
  mapContainer: {
    position: "relative",
    width: "100%",
    height: 300,
    marginTop: theme.spacing(4),
    borderRadius: 8,
    overflow: "hidden",
    boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
    transition: "transform 0.3s",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  mapIframe: {
    width: "100%",
    height: "100%",
  },
  formContainer: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
    backgroundColor: "#f8f8f8",
    borderRadius: 8,
    transition: "box-shadow 0.3s",
    "&:hover": {
      boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
    },
  },
  formField: {
    marginBottom: theme.spacing(2),
    fontSize: "1.125rem",
  },
  successMessage: {
    color: "green",
    marginTop: theme.spacing(2),
  },
  formLabel: {
    fontSize: "1.125rem",
  },
  whatsappContainer: {
    textAlign: "center",
    marginTop: theme.spacing(1),
  },
  videoWrapper: {
    position: "relative",
    width: "100%",
    paddingTop: "56.25%", // Relación de aspecto 16:9 56.25%
    borderRadius: 8,
    overflow: "hidden",
  },
  videoIframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    border: 0,
    borderRadius: 8,
    transition: "opacity 0.5s ease-in-out", // si deseas el mismo efecto fade
  },
}));

// Constantes para EmailJS (reemplaza con tus IDs reales)
const SERVICE_ID = "service_bydk6al";
const TEMPLATE_ID = "template_ls34vqb";
const USER_ID = "83DvZpsYj11je7wgz";

const extractVideoId = (url) => {
  try {
    if (url.includes("youtu.be")) {
      const parts = url.split("/");
      return parts[parts.length - 1].split("?")[0];
    } else {
      const urlObj = new URL(url);
      return new URLSearchParams(urlObj.search).get("v");
    }
  } catch (error) {
    return null;
  }
};

const PropiedadDetail = ({ data }) => {
  const classes = useStyles();
  const { propiedad } = data;

  // Array mixto de elementos (imágenes y video)
  const items = [
    propiedad?.imag1,
    propiedad?.imag2,
    propiedad?.imag3,
    propiedad?.imag4,
    propiedad?.imag5,
    propiedad?.imag6,
    propiedad?.imag7,
    propiedad?.imag8,
    propiedad?.imag9,
    propiedad?.imag10,
    propiedad?.video,
  ].filter((item) => item && item.trim() !== "");

  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoManuallyPaused, setVideoManuallyPaused] = useState(false);

  // Estados para el formulario y envío
  const [formData, setFormData] = useState({
    nombre: "",
    email: "",
    telefono: "",
    observaciones: "",
  });
  const [formSuccess, setFormSuccess] = useState(false);
  const [sending, setSending] = useState(false);

  const handleNext = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentIndex((prev) => (prev + 1) % items.length);
      setIsVideoPlaying(false);
      setVideoManuallyPaused(false);
      setFadeIn(true);
    }, 200);
  };

  const handlePrev = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentIndex((prev) =>
        prev === 0 ? items.length - 1 : prev - 1
      );
      setIsVideoPlaying(false);
      setVideoManuallyPaused(false);
      setFadeIn(true);
    }, 200);
  };

  const handleThumbnailClick = (index) => {
    if (index !== currentIndex) {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentIndex(index);
        setIsVideoPlaying(false);
        setVideoManuallyPaused(false);
        setFadeIn(true);
      }, 200);
    } else {
      const currentItem = items[index];
      if (!(currentItem.includes("youtube") || currentItem.includes("youtu.be"))) {
        setDialogOpen(true);
      }
    }
  };

  useEffect(() => {
    const currentItem = items[currentIndex];
    const isCurrentVideo =
      currentItem &&
      (currentItem.includes("youtube") || currentItem.includes("youtu.be"));
    if (isCurrentVideo && !videoManuallyPaused && !isVideoPlaying) {
      setIsVideoPlaying(true);
    }
  }, [currentIndex, items, videoManuallyPaused, isVideoPlaying]);

  useEffect(() => {
    const currentItem = items[currentIndex];
    const isCurrentVideo =
      currentItem &&
      (currentItem.includes("youtube") || currentItem.includes("youtu.be"));
    if (!isCurrentVideo) {
      const timer = setTimeout(() => {
        handleNext();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [currentIndex, items]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormSuccess(false);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Validación: todos los campos son obligatorios
  //   if (
  //     !formData.nombre.trim() ||
  //     !formData.email.trim() ||
  //     !formData.telefono.trim() ||
  //     !formData.observaciones.trim()
  //   ) {
  //     alert("Por favor, complete todos los campos.");
  //     return;
  //   }
  //   setSending(true);
  //   const templateParams = {
  //     nombre: formData.nombre,
  //     email: formData.email,
  //     telefono: formData.telefono,
  //     observaciones: formData.observaciones,
  //     to_email: "jerodriguezb@gmail.com", // Se envía a este correo desde la plantilla configurada
  //   };

  //   emailjs
  //     .send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
  //     .then(
  //       (response) => {
  //         console.log("SUCCESS!", response.status, response.text);
  //         setFormSuccess(true);
  //         setSending(false);
  //         setFormData({
  //           nombre: "",
  //           email: "",
  //           telefono: "",
  //           observaciones: "",
  //         });
  //       },
  //       (error) => {
  //         console.error("FAILED...", error);
  //         setFormSuccess(false);
  //         setSending(false);
  //         alert("Error al enviar el mensaje. Por favor, inténtelo de nuevo.");
  //       }
  //     );
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validación: todos los campos son obligatorios
    if (
      !formData.nombre.trim() ||
      !formData.email.trim() ||
      !formData.telefono.trim() ||
      !formData.observaciones.trim()
    ) {
      alert("Por favor, complete todos los campos.");
      return;
    }
    setSending(true);
  
    // Se arma un mensaje formateado para el cuerpo del correo
    const messageBody = `
      Nombre: ${formData.nombre}
      Email: ${formData.email}
      Teléfono: ${formData.telefono}
      Observaciones: ${formData.observaciones}
    `;
  
    // Se configuran los parámetros para el template
    const templateParams = {
      nombre: formData.nombre,
      email: formData.email,
      telefono: formData.telefono,
      observaciones: formData.observaciones,
      message: messageBody, // Puedes usar este campo en tu template para mostrar todos los datos formateados
      to_email: "inmobiliaria.ambito@gmail.com", // Este campo se puede configurar en el template en EmailJS
    };
  
    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormSuccess(true);
          setSending(false);
          setFormData({
            nombre: "",
            email: "",
            telefono: "",
            observaciones: "",
          });
        },
        (error) => {
          console.error("FAILED...", error);
          setFormSuccess(false);
          setSending(false);
          alert("Error al enviar el mensaje. Por favor, inténtelo de nuevo.");
        }
      );
  };
  

  const currentItem = items[currentIndex];
  const isCurrentVideo =
    currentItem && (currentItem.includes("youtube") || currentItem.includes("youtu.be"));

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {/* Columna izquierda: Carrusel y descripción */}
        <Grid item xs={12} md={8}>
          <div className={classes.carouselContainer}>
            {items.length > 1 && (
              <IconButton
                className={clsx(classes.arrowButton, classes.arrowLeft)}
                onClick={handlePrev}
              >
                <ArrowBackIosIcon />
              </IconButton>
            )}
            <div className={classes.imageWrapper}>
              {items.length > 0 && (
                <Fade in={fadeIn} timeout={500}>
                  {isCurrentVideo ? (
                    <div className={classes.videoWrapper}>
                      <iframe
                        className={classes.videoIframe}
                        src={`https://www.youtube.com/embed/${extractVideoId(currentItem)}?autoplay=1&mute=1`}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="video"
                      />
                      <IconButton
                        onClick={() => {
                          setIsVideoPlaying(false);
                          setVideoManuallyPaused(true);
                        }}
                        style={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          color: "#fff",
                          backgroundColor: "rgba(0,0,0,0.6)",
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  ) : (
                    <img
                      src={currentItem}
                      alt={`slide-${currentIndex}`}
                      className={classes.fadeImage}
                    />
                  )}

                </Fade>
              )}
            </div>
            {items.length > 1 && (
              <IconButton
                className={clsx(classes.arrowButton, classes.arrowRight)}
                onClick={handleNext}
              >
                <ArrowForwardIosIcon />
              </IconButton>
            )}
          </div>
          <div className={classes.thumbnailsContainer}>
            {items.map((item, index) => {
              const isVideoThumb =
                item.includes("youtube") || item.includes("youtu.be");
              const thumbSrc = isVideoThumb
                ? `https://img.youtube.com/vi/${extractVideoId(item)}/hqdefault.jpg`
                : item;
              return (
                <img
                  key={index}
                  src={thumbSrc}
                  alt={`thumb-${index}`}
                  className={clsx(classes.thumbnail, {
                    [classes.thumbnailActive]: index === currentIndex,
                  })}
                  onClick={() => handleThumbnailClick(index)}
                />
              );
            })}
          </div>
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            maxWidth="lg"
          >
            <DialogContent style={{ padding: 0 }}>
              <img
                src={currentItem}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            </DialogContent>
          </Dialog>
          <div className={classes.descriptionContainer}>
            <Typography className={classes.descriptionTitle}>
              Descripción de la Propiedad
            </Typography>
            <Divider className={classes.horizontalLine} />
            <Typography className={classes.descriptionText}>
              {propiedad?.Descripcion}
            </Typography>
            {/* <Typography className={classes.descriptionTitle}>
              Adicionales y Servicios
            </Typography> */}
            <Divider className={classes.horizontalLine} />
            <Typography className={classes.descriptionText}>
              {propiedad?.InformacionAdicional}
            </Typography>
            <div className={classes.mapContainer}>
              <div className={classes.mapIframe}>
                <SimpleMap
                  data={propiedad?.Latitud}
                  data2={propiedad?.Longitud}
                />
              </div>
            </div>
          </div>
        </Grid>
        {/* Columna derecha: Datos de la propiedad y formulario de contacto */}
        <Grid item xs={12} md={4}>
          <Paper className={classes.propertyDataContainer} elevation={2}>
            <Typography variant="h6" gutterBottom style={{ fontSize: "1.8rem" , fontWeight: "bold"  }}>
              Datos de la Propiedad
            </Typography>
            <Divider className={classes.horizontalLine} />
            <Typography className={classes.dataRow}>
              <strong>Código:</strong> {propiedad?.CodigoPropiedad}
            </Typography>
            <Divider className={classes.horizontalLine} />
            <Typography className={classes.dataRow}>
              <strong>Título:</strong> {propiedad?.Titulo}
            </Typography>
            <Divider className={classes.horizontalLine} />
            <Typography className={classes.dataRow}>
              <strong>Valor:</strong> {propiedad?.Valor}
            </Typography>
            <Divider className={classes.horizontalLine} />
            <Typography className={classes.dataRow}>
              <strong>Estado:</strong> {propiedad?.EstadoPropiedad}
            </Typography>
            <Divider className={classes.horizontalLine} />
            <Typography className={classes.dataRow}>
              <strong>Tipo:</strong> {propiedad?.TipoPropiedad}
            </Typography>
            <Divider className={classes.horizontalLine} />
            <Typography className={classes.dataRow}>
              <strong>Dirección:</strong> {propiedad?.DireccionFisica}
            </Typography>
            <Divider className={classes.horizontalLine} />
            <Typography className={classes.dataRow}>
              <strong>Barrio:</strong> {propiedad?.Barrio}
            </Typography>
            <Divider className={classes.horizontalLine} />
            <Typography className={classes.dataRow}>
              <strong>Lugar:</strong> {propiedad?.Lugar}
            </Typography>
            <Divider className={classes.horizontalLine} />
            {propiedad?.SuperficieTotal && (
              <>
                <Typography className={classes.dataRow}>
                  <strong>Sup. Total:</strong> {propiedad?.SuperficieTotal}
                </Typography>
                <Divider className={classes.horizontalLine} />
              </>
            )}
            {propiedad?.SuperficiePropia && (
              <>
                <Typography className={classes.dataRow}>
                  <strong>Sup. Propia:</strong> {propiedad?.SuperficiePropia}
                </Typography>
                <Divider className={classes.horizontalLine} />
              </>
            )}
            {propiedad?.SuperficieComun && (
              <>
                <Typography className={classes.dataRow}>
                  <strong>Sup. Común:</strong> {propiedad?.SuperficieComun}
                </Typography>
                <Divider className={classes.horizontalLine} />
              </>
            )}
            {propiedad?.SuperficieCubierta && (
              <>
                <Typography className={classes.dataRow}>
                  <strong>Sup. Cubierta:</strong> {propiedad?.SuperficieCubierta}
                </Typography>
                <Divider className={classes.horizontalLine} />
              </>
            )}
            {propiedad?.SuperficieSemiCubierta && (
              <>
                <Typography className={classes.dataRow}>
                  <strong>Sup. Semi Cubierta:</strong> {propiedad?.SuperficieSemiCubierta}
                </Typography>
                <Divider className={classes.horizontalLine} />
              </>
            )}
            <Typography
              variant="h6"
              gutterBottom
              style={{ fontSize: "1.8rem", marginTop: 16, fontWeight: "bold"  }}
            >
              Adicionales
            </Typography>
            <Divider className={classes.horizontalLine}
            style={{ marginBottom: 16 }} 
           />
            {propiedad?.Ambientes && (
              <div className={classes.serviceItem}>
                <HomeWorkIcon className={classes.serviceIcon} />
                Ambientes: {propiedad?.Ambientes}
              </div>
            )}
            {propiedad?.Dormitorio && (
              <div className={classes.serviceItem}>
                <KingBedIcon className={classes.serviceIcon} />
                Dormitorios: {propiedad?.Dormitorio}
              </div>
            )}
            {propiedad?.Baño && (
              <div className={classes.serviceItem}>
                <BathtubIcon className={classes.serviceIcon} />
                Baños: {propiedad?.Baño}
              </div>
            )}
            {propiedad?.Cocina && (
              <div className={classes.serviceItem}>
                <KitchenIcon className={classes.serviceIcon} />
                Cocina
              </div>
            )}
            {propiedad?.Pileta && (
              <div className={classes.serviceItem}>
                <PoolIcon className={classes.serviceIcon} />
                Pileta
              </div>
            )}
            {propiedad?.Cochera && (
              <div className={classes.serviceItem}>
                <DirectionsCarIcon className={classes.serviceIcon} />
                Cochera
              </div>
            )}

          </Paper>
          <Paper className={classes.formContainer} elevation={2}>
            <Typography variant="h6" gutterBottom style={{ fontSize: "1.25rem" }}>
              Contáctanos
            </Typography>
            <Divider style={{ margin: "4px 0" }} />
            <form onSubmit={handleSubmit}>
              <TextField
                label="Nombres"
                variant="outlined"
                fullWidth
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                InputLabelProps={{ className: classes.formLabel }}
                InputProps={{ style: { fontSize: "1.125rem" } }}
                className={classes.formField}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                value={formData.email}
                onChange={handleChange}
                InputLabelProps={{ className: classes.formLabel }}
                InputProps={{ style: { fontSize: "1.125rem" } }}
                className={classes.formField}
              />
              <TextField
                label="Teléfono de contacto"
                variant="outlined"
                fullWidth
                name="telefono"
                value={formData.telefono}
                onChange={handleChange}
                InputLabelProps={{ className: classes.formLabel }}
                InputProps={{ style: { fontSize: "1.125rem" } }}
                className={classes.formField}
              />
              <TextField
                label="Observaciones"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                name="observaciones"
                value={formData.observaciones}
                onChange={handleChange}
                InputLabelProps={{ className: classes.formLabel }}
                InputProps={{ style: { fontSize: "1.125rem" } }}
                className={classes.formField}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                style={{ marginTop: 8 }}
                disabled={sending}
              >
                {sending ? "Enviando..." : "Enviar Mensaje"}
              </Button>
              {formSuccess && (
                <Typography variant="body2" className={classes.successMessage}>
                  ¡Mensaje enviado exitosamente!
                </Typography>
              )}
            </form>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default PropiedadDetail;


import React from "react";
import {
  makeStyles,
  Container,
  Box,
  Grid,
  Link,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Facebook, Instagram } from "@material-ui/icons";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import logo from "../images/logojpg3.jpeg"

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#000", // Fondo oscuro
    color: "#fff",           // Texto claro
    padding: theme.spacing(4, 2),
    width: "100%",
  },
  logo: {
    width: 100,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: 100,
    },
  },
  columnTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    textTransform: "uppercase",
  },
  link: {
    color: "#fff",
    textDecoration: "none",
    display: "block",
    marginBottom: theme.spacing(1),
    transition: "color 0.3s",
    "&:hover": {
      color: "#ccc", // color al pasar el mouse
    },
  },
  socialIcon: {
    color: "#fff",
    marginRight: theme.spacing(1),
    transition: "color 0.3s",
    "&:hover": {
      color: "#ccc", // color al pasar el mouse
    },
  },
  contactInfo: {
    marginBottom: theme.spacing(1),
  },
  brandText: {
    marginTop: theme.spacing(1),
  },
  bottomBar: {
    marginTop: theme.spacing(3),
    borderTop: "1px solid #333",
    paddingTop: theme.spacing(2),
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <Box component="footer" className={classes.footer}>
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          {/* Columna 1: Logo + Autoría */}
          <Grid item xs={12} sm={6} md={3}>
            <img
              // Ajusta la ruta a la ubicación real de tu nuevo logo
              src={logo} 
              alt="Ambito Inmobiliaria Logo"
              className={classes.logo}
            />
            {/* <Typography variant="body1" className={classes.brandText}>
              © 2025 <strong>Ambito Inmobiliaria</strong>
            </Typography>
            <Typography variant="subtitle1">
              <strong>by Abeto Tech - División Web</strong>
            </Typography> */}
          </Grid>

          {/* Columna 2: Enlaces adicionales */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" className={classes.columnTitle}>
              Información
            </Typography>
            <Link href="#!" variant="h6" className={classes.link}>
              Políticas de Privacidad
            </Link>
            <Link href="#!" variant="h6" className={classes.link}>
              Términos y Condiciones
            </Link>
            {/* <Link href="#!" variant="h6" className={classes.link}>
              Code of Conduct
            </Link> */}
          </Grid>

          {/* Columna 3: Contacto */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" className={classes.columnTitle}>
              Contacto
            </Typography>
            <Typography variant="h6" className={classes.contactInfo}>
              Por consultas comuníquese con nosotros:
            </Typography>
            <Typography variant="h6" className={classes.contactInfo}>
              Lun a Sab de 9 a 18 Hs - Tel +3814253622
            </Typography>
            <Typography variant="h6" className={classes.contactInfo}>
              Cel +5493816900030 | +5493814909195
            </Typography>
            <Typography variant="h6" className={classes.contactInfo}>
              Email: inmobiliaria.ambito@gmail.com
            </Typography>
          </Grid>

          {/* Columna 4: Redes Sociales */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" className={classes.columnTitle}>
              Síguenos
            </Typography>
            <Box display="flex" alignItems="center">
              <IconButton
                className={classes.socialIcon}
                href="https://www.facebook.com/AmbitoInmobiliariaTuc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook />
              </IconButton>
              <IconButton
                className={classes.socialIcon}
                href="https://instagram.com/ambitoinmobiliariatuc?utm_medium=copy_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </IconButton>
              <IconButton
                className={classes.socialIcon}
                href="https://wa.link/0zbip1"
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsAppIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>

        {/* Barra inferior (texto extra o disclaimer) */}
        <Box className={classes.bottomBar}>
          <Typography variant="subtitle1" align="center">
          © 2025 <strong>by Abeto Tech</strong> <strong>Contacto: 0381-5176145 / 5324678</strong>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
